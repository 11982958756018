import React from 'react';
import style from './RecognizeStage.module.css';
import Container from '../Container/Container';
import {DocumentInfo} from '../../@types/api';
import RecognizeStageItem from './RecognizeStageItem';
import {StartRecognizePayload} from '../../store/types';

export interface RecognizeStageProps {
  documents: DocumentInfo[];
  onRecognize: (payload: StartRecognizePayload) => void;
  onReset: () => void;
}

type FieldType = {
  name: string;
  text: string;
  docType: string;
  crop: string;
  bbox: Array<Array<number>>;
};

type FieldObject = {
  [key: string]: FieldType;
};

type ResultFieldType = Omit<FieldType, 'docType' | 'crop'>;

type ResultType = {
  crop: string;
  docType: string;
  fields: {
    [key: string]: ResultFieldType;
  };
};

const transformFields = (fields: FieldObject): ResultType[] => {
  const cropsMap: { [key: string]: ResultType } = {};

  for (const key in fields) {
    const field = fields[key];
    const { crop, docType, ...resultField } = field;

    const cropKey = `${crop}_${docType}`;
    if (cropsMap[cropKey]) {
      cropsMap[cropKey].fields[key] = resultField;
    } else {
      cropsMap[cropKey] = {
        crop,
        docType,
        fields: {
          [key]: resultField,
        },
      };
    }
  }

  return Object.values(cropsMap);
};

export default function RecognizeStage({
  documents,
  onReset,
  onRecognize,
}: RecognizeStageProps) {
  return (
      <div className={style.root}>
        <Container noMaxWidth>
          {
              documents.map((document: DocumentInfo, index) => (
                      <RecognizeStageItem
                          isLoading={document.isRecognizeLoading}
                          progress={document.recognizeProgress}
                          key={index}
                          documentInfo={document}
                          onRecognize={onRecognize}
                      />
                  )
              )
          }
        </Container>
      </div>
  );
}