import axios, { AxiosResponse } from 'axios'
import store from '../store'
import { StartRecognizePayload } from '../store/types'
import { ClassifyResponse, RecognizeResponseData } from '../@types/api'

const host = 'https://docr4.kns.handl.ai'
const path = 'pipeline/recognize_kns/'

export class Api {
  static async classify(acceptedFiles: File[]): Promise<AxiosResponse<any>> {
    const file = acceptedFiles[0]
    const formData = new FormData()
    formData.append('image', file)

    const response = axios.post<ClassifyResponse>(
      `${host}/${path}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          'handl_gateway': 'https://label.kns.handl.ai/api/v1',
          //'handl_gateway': 'https://handl.handl.dbrain.io/api/v1',
          'handl_deadline_timeout': '300',
          'confidence_threshold': '0.8',
	  'return_crops': true,
        }
      },
    )
    return response
  }

  static async recognize({
    document,
    withHitl,
  }: StartRecognizePayload): Promise<AxiosResponse<any>> {
    const state = store.getState()

    const res = await fetch(document.image)
    const blob = await res.blob()

    var formData = new FormData()
    formData.append('image', blob)

    return axios.post<RecognizeResponseData>(`${host}/${path}`, formData, {
      // timeout: 20000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        doc_type: document.doc_type,
        mode: 'default',
        with_hitl: withHitl,
        hitl_sla: 'night',
        quality: 75,
        dpi: 300,
        pdf_raw_images: true,
        token: state.token,
      },
    })
  }
}
